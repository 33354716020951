<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    title="开通结算账户"
    width="35%"
    :before-close="handleClose"
  >
    <el-form ref="formRef" label-position="left" label-width="100px" :model="formData" :rules="rules">
      <el-form-item label="银行开户名" prop="openBankName">
        <el-input v-model="formData.openBankName" disabled />
      </el-form-item>
      <el-form-item v-if="formData.settleBankId === 'ABC'" label="开户银行" prop="crBankNo" :rules="rules.selectiveType">
        <el-select v-model="formData.crBankNo" size="large" @change="getCrBankNo">
          <el-option
            v-for="item in depositBankList"
            :key="item.dictId"
            :label="item.dictName"
            :value="item.dictId"
          />
        </el-select>
      </el-form-item>
      <div>
        <el-form-item v-if="formData.settleBankId === 'ABC'" label="银行卡号" prop="crAccNo" :rules="[...rules.message, { min: 10, max: 20, message: '长度在10-20个字符', trigger: 'blur' }]">
          <el-input ref="cardInput" v-model="formData.crAccNo" placeholder="请输入公司银行卡号" @input="getOpenAccNo" />
        </el-form-item>
        <el-form-item v-else label="银行卡号" prop="openAccNo" :rules="[...rules.message, { min: 10, max: 20, message: '长度在10-20个字符', trigger: 'blur' }]">
          <el-input ref="cardInput" v-model="formData.openAccNo" placeholder="请输入公司银行卡号" @input="getOpenAccNo" />
        </el-form-item>
      </div>
      <el-form-item v-if="formData.settleBankId !== 'ABC'" label="开户行网点" prop="openBankNet" :rules="rules.message">
        <el-input v-model="formData.openBankNet" placeholder="请输入开户行网点" />
      </el-form-item>
      <el-form-item label="手机号" prop="telNo" :rules="rules.phone">
        <el-input v-model="formData.telNo" placeholder="请输入银行留存手机号" />
      </el-form-item>
      <el-form-item v-if="formData.settleBankId === 'ABC'" label="短信验证码" prop="billNo" :rules="[...rules.message, { min: 6, max: 6, message: '长度为6个字符', trigger: 'blur' }]">
        <el-input v-model="formData.billNo" placeholder="请输入6位数字" />
        <el-button class="verification-code" :disabled="disabledMyCode" @click="getMyCode">
          {{ codeText }}
        </el-button>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="widen-button" @click="handleClose">取消</el-button>
        <el-button type="primary" class="widen-button" :loading="$store.state.loading" @click="openBankAccount">立即开通</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { rules } from '@/unit/matchFormRegex'
import { sendPhoneCode } from '@/http/login'
import { abcBailPaySubContractSign, cmpBankinfoAdd } from '@/http/account'
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      depositBankList: [],
      formData: {},
      rules,
      codeText: '获取验证码',
      disabledMyCode: false
    }
  },
  watch: {
    data: {
      handler(val) {
        this.formData = val
      },
      immediate: true
    }
  },
  created() {
    this.depositBankList = this.$store.getters.getDictionaryItem('ABC')
    this.formData.settleBankName = this.$store.getters.getDictionaryItem('settleBankName') && this.$store.getters.getDictionaryItem('settleBankName').length > 0 ? (this.$store.getters.getDictionaryItem('settleBankName').find((item) => item.dictId === this.formData.settleBankId)).dictName : ''
  },
  methods: {
    // 获取开户银行name id
    getCrBankNo(data) {
      const currentVal = this.depositBankList && this.depositBankList.length > 0 ? this.depositBankList.find(item => item.dictId === data) : {}
      this.formData.crBankName = currentVal.dictName || ''
    },
    async openBankAccount() {
      const valid = await this.$refs.formRef.validate().catch(() => false)
      if (!valid) return
      let api = ''
      if (this.formData.settleBankId === 'ABC') {
        this.formData.crAccNo = this.formData.crAccNo.replace(/\s*/g, '')
        api = abcBailPaySubContractSign
      } else {
        this.formData.openAccNo = this.formData.openAccNo.replace(/\s*/g, '')
        api = cmpBankinfoAdd
      }
      api(this.formData, res => {
        this.$message.success('成功！')
        this.handleClose()
      })
    },
    handleClose() {
      this.$emit('update:dialogVisible', false)
      this.$emit('getData')
    },
    // 获取验证码
    getMyCode() {
      if (!this.formData.telNo || this.formData.telNo === '' || this.formData.telNo.length !== 11) return this.$message.error('请填写正确的手机号码')
      sendPhoneCode(this.formData.telNo, res => {
        let maxNum = 60
        const oldCodeText = this.codeText
        this.codeText = `已发送${maxNum}s`
        const codeCountDown = setInterval(() => {
          this.disabledMyCode = true
          const countDownNum = maxNum--
          this.codeText = `已发送${countDownNum}s`
          if (countDownNum === 0) {
            // 倒计时结束
            this.codeText = oldCodeText
            clearInterval(codeCountDown)
            this.disabledMyCode = false
          }
        }, 1000)
      })
    },
    // 银行卡号分隔
    getOpenAccNo(cardNum) {
      // 获取input的dom对象，这里因为用的是element ui的input，所以需要这样拿到
      const input = this.$refs.cardInput.$el.getElementsByTagName('input')[0]
      // 获取当前光标的位置
      const cursorIndex = input.selectionStart
      // 字符串中光标之前-的个数
      const lineNumOfCursorLeft = (cardNum.slice(0, cursorIndex).match(/ /g) || []).length
      // 去掉所有-的字符串
      const noLine = cardNum.replace(/ /g, '')
      // 去除格式不对的字符并重新插入-的字符串
      const newCardNum = noLine.replace(/\D+/g, '').replace(/(\d{4})/g, '$1 ').replace(/ $/, '')
      // 改后字符串中原光标之前-的个数
      const newLineNumOfCursorLeft = (newCardNum.slice(0, cursorIndex).match(/ /g) || []).length
      // 光标在改后字符串中应在的位置
      const newCursorIndex = cursorIndex + newLineNumOfCursorLeft - lineNumOfCursorLeft
      // 赋新值，nextTick保证-不能手动输入或删除，只能按照规则自动填入
      this.$nextTick(() => {
        if (this.formData.settleBankId === 'ABC') {
          this.formData.crAccNo = newCardNum
        } else {
          this.formData.openAccNo = newCardNum
        }
        // 修正光标位置，nextTick保证在渲染新值后定位光标
        this.$nextTick(() => {
        // selectionStart、selectionEnd分别代表选择一段文本时的开头和结尾位置
          input.selectionStart = newCursorIndex
          input.selectionEnd = newCursorIndex
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item__content {
  .el-select, .el-input {
    width: 72%;
  }
}
.verification-code {
  margin-left: 10px;
}
</style>
