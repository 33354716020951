<template>
  <div class="orderPaymentPageBox">
    <!-- 支付订单的页面 -->
    <div v-if="orderType === 1" class="order-payment-box clear">
      <div class="orderPayment">
        <!-- 上面订单的详情 -->
        <div class="orderDetails">
          <div class="bg-box">
            <!-- 订单编号之类的 -->
            <div class="orederNo">
              <div class="flex font">
                <p>订单提交成功，请尽快付款！</p>
                <p class="number">
                  订单编号 <span class="price">{{ orderData.orderNo }}</span>
                </p>
              </div>
              <p class="tip">
                请在营业时间内完成付款，否则订单会被自动取消
              </p>
            </div>
            <!-- 货款金额 -->
            <div class="amount">
              <p class="price">
                应付总额<span>&nbsp;&nbsp;￥{{ orderData.orderCopeAmount }}</span>
              </p>
              <div class="flex">
                <p>
                  <span>货款金额：￥{{ orderData.orderAmount }}</span>
                </p>
                <p>
                  <span>运费：￥{{ orderData.orderFreight || "0.00" }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 没有结算账户 开通结算账户 -->
        <div
          v-if="
            (accountListData.length === 0 || !accountListData) &&
              orderType === 1
          "
          class="modePayment"
        >
          <p class="title">
            开通结算账户(商家支持)
          </p>
          <div class="accountManagement">
            <div class="bank">
              <img
                v-if="orderData.settleBankId === 'ABC'"
                class="bankImg"
                src="@/assets/img/big_ABC.png"
                alt=""
              >
              <img
                v-if="orderData.settleBankId === 'ICBC'"
                class="bankImg"
                src="@/assets/img/big_ICBC.png"
                alt=""
              >
              <img
                v-if="orderData.settleBankId === 'LSBANK'"
                class="bankImg"
                src="@/assets/img/big_LSBANK.png"
                alt=""
              >
              <h3 class="elp-1">
                {{
                  $store.getters.getDictionaryItem("settleBankName") &&
                    $store.getters.getDictionaryItem("settleBankName").length > 0
                    ? $store.getters
                      .getDictionaryItem("settleBankName")
                      .find((item) => item.dictId === orderData.settleBankId)
                      .dictName
                    : ""
                }}
              </h3>
              <p class="open" @click="openBankDialog">
                暂未开通，请先<span>开通</span>
              </p>
            </div>
          </div>
        </div>
        <!-- 选择账户支付 -->
        <div
          v-else-if="accountListData.length > 0 && orderType === 1"
          class="settlementAccount"
        >
          <div class="account-data">
            <p class="title">
              已选结算账号
            </p>
            <div
              v-for="(item, index) in accountListData"
              :key="index"
              class="accountList"
            >
              <div class="item">
                <div class="left">
                  <!-- UI图上是有两种图标的记号  禁用跟再用   如果没有两种  就不要禁用状态了  -->
                  <img
                    v-if="item.settleBankId === 'ABC'"
                    class="bankImg"
                    src="@/assets/img/big_ABC.png"
                    alt=""
                  >
                  <img
                    v-if="item.settleBankId === 'ICBC'"
                    class="bankImg"
                    src="@/assets/img/big_ICBC.png"
                    alt=""
                  >
                  <img
                    v-if="item.settleBankId === 'LSBANK'"
                    class="bankImg"
                    src="@/assets/img/big_LSBANK.png"
                    alt=""
                  >
                  <p class="settle-name">
                    {{ item.settleBankName }}
                  </p>
                  <p v-if="item.openAccNo" class="bank">
                    储蓄卡 (&nbsp;&nbsp;尾号{{ item.openAccNo.substr(-4) }} )
                  </p>
                  <p v-if="item.settleBankId !== 'ABC'" class="limit">
                    可用额度：{{ item.avlbFund }}元
                  </p>
                </div>
                <!-- 这里是不能支付的原因  根据状态要改哈  何大佬 -->
                <p
                  v-if="
                    Number(item.avlbFund) < Number(orderData.orderCopeAmount) &&
                      item.settleBankId !== 'ABC'
                  "
                  class="right"
                  @click="recharge"
                >
                  账户可用余额不足，请先<span>充值</span>
                </p>
              </div>
            </div>
            <div class="paymentButton">
              <span class="button" @click="immediatePayment"> 立即支付 </span>
              <span class="tip-box" @click="skip">资金不足？<i>去融资</i></span>
            </div>
          </div>
        </div>
      </div>
      <img class="advertising-banner" src="@/assets/image/advertising_banner.jpg" alt="advertising" @click="skip">
    </div>
    <!-- 支付完成的页面1 -->
    <div v-if="orderType === 2" class="completionPayment orderPayment">
      <div class="orderAndNumber">
        <img src="@/assets/icon/icon6.png" alt="icon">
        <p class="tip">
          订单已提交，待商家确认运费后完成付款
        </p>
        <p class="number">
          订单编号：{{ orderDetails.orderNo }}
        </p>
        <div class="flex">
          <p class="return-home">
            <router-link :to="{ path: '/' }">
              返回首页
            </router-link>
          </p>
          <p class="lookOrder" @click="viewOrder">
            查看订单
          </p>
        </div>
      </div>
    </div>
    <!-- 支付完成2 -->
    <div v-else-if="orderType === 3" class="completionPayment">
      <div class="orderAndNumber">
        <img src="@/assets/icon/icon5.png" alt="icon">
        <p class="tip">
          恭喜您，支付成功
        </p>
        <p class="number">
          <span> 订单编号：{{ orderData.orderNo }} </span>
          <span>支付金额：{{ orderData.orderCopeAmount }}</span>
          <span>支付方式：{{
            accountListData[0] && accountListData[0].settleBankName
          }}&nbsp;(尾号{{
            accountListData[0] && accountListData[0].openAccNo.substr(-4)
          }})</span>
        </p>
        <div class="flex">
          <p class="return-home">
            <router-link :to="{ path: '/' }">
              返回首页
            </router-link>
          </p>
          <p class="lookOrder" @click="viewOrder">
            查看订单
          </p>
        </div>
      </div>
    </div>
    <!-- 添加银行卡 -->
    <OpenAccount
      :dialog-visible.sync="dialogFormVisible"
      :data="form"
      @getData="getBankInfo"
    />
  </div>
</template>

<script>
import { accountInfoPage } from '@/http/account'
import { tradeOrdePayOrder } from '@/http/order'
import OpenAccount from '@/components/OpenAccount.vue'
export default {
  components: { OpenAccount },
  data() {
    return {
      form: {
        openBankName: JSON.parse(
          localStorage.getItem(
            `mallFrontDeskUserInfo${this.$store.state.userCmpRole}`
          )
        ).orgName,
        cmpId: JSON.parse(
          localStorage.getItem(
            `mallFrontDeskUserInfo${this.$store.state.userCmpRole}`
          )
        ).orgName,
        cmpName: JSON.parse(
          localStorage.getItem(
            `mallFrontDeskUserInfo${this.$store.state.userCmpRole}`
          )
        ).orgName,
        settleBankId: sessionStorage.orderData
          ? JSON.parse(sessionStorage.orderData).settleBankId
          : ''
      },
      orderType: null,
      dialogFormVisible: false,
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }]
      },
      // 该订单信息
      orderData: sessionStorage.orderData
        ? JSON.parse(sessionStorage.orderData)
        : {},
      // 可用账户列表
      accountListData: [],
      // 查询可以账户参数
      bankParameter: {
        cmpId:
          JSON.parse(
            localStorage.getItem(
              `mallFrontDeskUserInfo${this.$store.state.userCmpRole}`
            )
          ).cmpId || '',
        settleBankId: sessionStorage.orderData
          ? JSON.parse(sessionStorage.orderData).settleBankId
          : ''
      },
      // 支付参数
      payParam: {
        orderId: sessionStorage.orderData
          ? JSON.parse(sessionStorage.orderData).id
          : '',
        // orderTotalAmount: 0,
        settleBankId: ''
      },
      token: null
      // countDown: 30
    }
  },
  created() {
    this.token = localStorage.getItem(
      `mallFrontDeskToken${this.$store.state.userCmpRole}`
    )
    this.getBankInfo()
  },
  mounted() {
    this.orderDetails = JSON.parse(sessionStorage.orderData)
    if (this.$route.query.merchantDistribution) {
      switch (this.$route.query.merchantDistribution) {
        case '1':
          this.orderType = 2
          break
        default:
          this.orderType = 1
          break
      }
    }
    this.payParam.orderTotalAmount = Number(this.orderData.orderCopeAmount)
  },
  methods: {
    // 跳转页面
    skip() {
      const routeUrl = this.$router.resolve({
        path: '/industryFinance/index'
      })
      window.open(routeUrl.href, '_blank')
    },
    // 查询开户信息列表
    getBankInfo() {
      accountInfoPage(this.bankParameter, (res) => {
        this.accountListData = [...res.data.pageData]
        if (res.data.pageData && res.data.pageData.length > 0) {
          this.payParam.settleBankId = this.accountListData[0].settleBankId
        }
      })
    },
    // 支付
    async immediatePayment() {
      if (!this.payParam.settleBankId) {
        return this.$message.warning('请选择结算账户')
      }
      const confirm = await this.$confirm('是否支付当前订单?', '提示', {
        type: 'warning'
      }).catch((err) => err)
      if (confirm !== 'confirm') return
      const loading = this.$loading({
        lock: true,
        text: '正在支付中，请稍后...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      tradeOrdePayOrder(
        this.payParam,
        (res) => {
          if (res.data && res.data.length > 0) {
            window.open(res.data, '_blank')
            loading.close()
            window.open(
              `${this.$projectUrl}login?token=${this.token}&cmpRole=${this.$store.state.userCmpRole}&path=/orderManage/orderManageBuyersIndex`,
              '_self'
            )
            return
          }
          this.orderType = 3
          loading.close()
        },
        (err) => {
          loading.close()
          if (err.code === 1013) {
            this.$confirm('账户余额不足, 是否充值', {
              confirmButtonText: '确认充值',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                this.recharge()
              })
              .catch(() => {})
          }
          if (err.code === 500) {
            this.$confirm('支付超时，订单已自动取消！', {
              confirmButtonText: '我知道了',
              showCancelButton: false,
              showClose: false,
              type: 'warning'
            })
              .then(() => {
                window.open(
                  `${this.$projectUrl}login?token=${this.token}&cmpRole=${this.$store.state.userCmpRole}&path=/orderManage/orderManageBuyersIndex&orderState=14`,
                  '_blank'
                )
                this.closeWindow()
              })
              .catch(() => {})
          }
        }
      )
    },
    // 关闭当前浏览器
    closeWindow() {
      var userAgent = navigator.userAgent
      if (
        userAgent.indexOf('Firefox') !== -1 ||
        userAgent.indexOf('Chrome') !== -1
      ) {
        window.location.replace(' ', '_self')
      } else {
        window.opener = null
        window.open(' ', '_self')
      }
      window.close()
    },
    // 开通账户弹窗
    openBankDialog() {
      this.dialogFormVisible = true
    },
    // 充值 跳转后台
    recharge() {
      window.open(
        `${this.$projectUrl}login?token=${this.token}&cmpRole=${this.$store.state.userCmpRole}&path=/fundsManage/addTopUp&settleBankId=${this.payParam.settleBankId}`,
        '_blank'
      )
    },
    // 查看订单
    viewOrder() {
      window.open(
        `${this.$projectUrl}login?token=${this.token}&cmpRole=${this.$store.state.userCmpRole}&path=/orderManage/orderManageBuyersIndex`,
        '_blank'
      )
    }
  }
}
</script>

<style lang="scss">
.orderPaymentPageBox {
  padding: 24px 0 80px 0;
  background-color: #f9f9f9;
  .order-payment-box{
    width: $centerPlateWidth;
    margin: auto;
    .advertising-banner{
      float: right;
      width: 270px;
      cursor: pointer;
    }
    .orderPayment{
      width: 906px;
      float: left;
    }
  }
  .completionPayment {
    background-color: #fff;
    padding: 138px 0 348px;
    display: flex;
    align-items: center;
    justify-content: center;
    .orderAndNumber {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding-top: 11px;
      .number {
        padding: 16px 0 24px;
        font-size: 14px;
        color: $mainFont;
        span {
          margin-left: 24px;
        }
      }
      .return-home {
        width: 128px;
        height: 38px;
        box-sizing: border-box;
        line-height: 38px;
        text-align: center;
        color: $themeColors;
        cursor: pointer;
        background-color: #fff;
        border: 1px solid $themeColors;
        margin-right: 10px;
        a {
          color: $themeColors;
        }
      }
      .lookOrder {
        box-sizing: border-box;
        width: 128px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color: #fff;
        cursor: pointer;
        background-color: $themeColors;
      }
      .tip {
        padding-top: 11px;
        color: $mainFont;
        font-size: 16px;
        font-weight: 800;
      }
    }
    img {
      height: 67px;
      width: 67px;
      margin-right: 20px;
    }
  }

  .el-form {
    padding: 0 20px;
    .codeBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .code {
        margin-left: 20px;
        text-align: center;
        height: 38px;
        cursor: pointer;
        width: 104px;
        color: $ornamentColor;
        font-size: 12px;
        background-color: #f4f6fa;
      }
      .el-input {
        flex: 1;
      }
    }
    .bankName {
      .el-form-item__label::before {
        color: #fff;
      }
    }
    .el-form-item__label {
      text-align: left;
    }
  }
  .orderPayment {
    width: $centerPlateWidth;
    margin: auto;
    .modePayment {
      padding: 33px 40px;
      background-color: #fff;
      .accountManagement {
        display: flex;
        margin-top: 32px;
        .open {
          color: $mainFont;
          cursor: pointer;
          span {
            color: $themeColors;
          }
        }
        .bank {
          display: flex;
          align-items: center;
          h3 {
            color: $mainFont;
            font-weight: 700;
            font-size: 16px;
            margin-left: 8px;
            margin-right: 128px;
          }
          img {
            width: 32px;
            height: 32px;
          }
        }
      }
      .title {
        font-size: 20px;
        color: #333;
        font-weight: 500;
      }
    }
    .settlementAccount {
      .account-data {
        background-color: #fff;
        .paymentButton {
          padding: 42px 20px 24px;
          text-align: left;
          .tip-box{
            margin-left: 24px;
            font-size: 16px;
            color: #999999;
            i{
              font-style: normal;
              color: $themeColors;
              text-decoration: underline;
            }
          }
          .button {
            text-align: center;
            cursor: pointer;
            height: 38px;
            width: 128px;
            display: inline-block;
            line-height: 38px;
            color: #fff;
            background-color: $themeColors;
            font-size: 14px;
          }
        }
      }

      .el-radio-group {
        width: 100%;
        .el-radio {
          margin: 0 16px 0 24px;
          .el-radio__label {
            display: none;
          }
        }
      }
      .accountList {
        padding: 40px 24px 16px 24px;
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 50px;
          color: $mainFont;
          .left {
            display: flex;
            align-items: center;
            flex: 1;
          }
          .right {
            color: $fontColor5;
            font-size: 14px;
            cursor: pointer;
            span {
              color: $themeColors;
            }
          }
          .settle-name {
            margin-right: 80px;
          }
          .limit {
            font-size: 14px;
            border-left: 1px solid #e7e7e7;
            padding-left: 12px;
          }
          .bank {
            font-size: 16px;
            padding-right: 12px;
          }
          img {
            height: 34px;
            width: 34px;
            margin-right: 8px;
          }
        }
        // .disabled {
        //   // color: $fontColor1;
        // }
        .active {
          background-color: #fff9f9;
        }
      }
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 24px 0;
        font-size: 20px;
        color: $mainFont;
        span {
          color: $ornamentColor;
          cursor: pointer;
          font-weight: 500;
        }
      }
    }
    .orderDetails {
      padding: 0 20px;
      background-color: #fff;
      .bg-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e7e7e7;
      }
      .orederNo {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 108px;
        color: $mainFont;
        font-size: 14px;
        font-weight: 700;
        .tip {
          color: $fontColor5;
          font-size: 14px;
          font-weight: 400;
          margin-top: 8px;
        }
        .number {
          // padding: 10px 0;
          margin-left: 15px;
          span {
            margin-left: 6px;
            color: $themeColors;
          }
        }
        .font {
          p {
            font-size: 18px;
            font-weight: 500;
            color: #333;
          }
          .number {
            font-size: 18px;
            color: #333;
          }
        }
      }
      .amount {
        text-align: right;
        p {
          color: $mainFont;
          font-size: 12px;
          padding-left: 14px;
          padding-bottom: 11px;
          span {
            display: inline-block;
            color: $fontColor5;
            font-weight: 500;
            font-size: 12px;
          }
        }
        p:last-of-type {
          padding-bottom: 0;
        }
        .price {
          margin-bottom: 8px;
          span {
            font-size: 18px;
            font-weight: 800;
            color: $themeColors;
          }
        }
      }
    }
  }
}
.count-down {
  margin-top: 20px;
  color: $mainFont;
  span {
    font-weight: bold;
    color: $ornamentColor;
  }
}
.flex {
  display: flex;
}
</style>
